var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"duihuans"},[_c('div',{staticClass:"duihuan"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(true),expression:"true"}],staticClass:"duihuan-right"},[_c('div',{staticClass:"page-header"},[_c('p',[_c('strong',[_vm._v("订单详情-"+_vm._s(_vm.orderInfoData.sn))])])]),_c('div',{staticClass:"duihuan-right-ding"},[_c('div',{staticClass:"duihuan-right-ding-center"},[_c('div',{staticClass:"duihuan-right-dingdan"},[_c('div',{staticClass:"duihuan-right-one"},[_c('div',{staticClass:"duihuan-right-dingdan-top"},[_vm._v("订单信息")]),_c('div',{staticClass:"duihuan-right-dingdan-bottom"},[_c('div',{staticClass:"duihuan-right-dingdan-bottom-left"},[_vm._m(0),_c('div',{staticClass:"duihuan-right-dingdan-one-bianhao1-number"},[_c('li',[_vm._v(_vm._s(_vm.orderInfoData.sn))]),_c('li',[_vm._v(" "+_vm._s(_vm.orderInfoData.status_name)+" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_vm._v("签收后15天系统将自动确认收货")])]),_c('li')])]),_c('div',{staticClass:"duihuan-right-dingdan-bottom-center"},[_c('ul',[_c('li',[_c('img',{attrs:{"src":require("@/icons/svg/shop/倒计时12X12.svg"),"alt":""}}),_c('span',[_vm._v(_vm._s(_vm.orderInfoData.expired_time))])]),_c('li',[_c('button',{staticClass:"button-blue-100-14",on:{"click":_vm.confimGoods}},[_vm._v(" 确认收货 ")])])])]),_c('div',{staticClass:"duihuan-right-dingdan-bottom-right"},[_c('ul',[_vm._m(1),_c('li',[_c('a',{attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.$router.push({
                              path: 'detail',
                              query: { id: _vm.goodsId },
                            })}}},[_vm._v("再次购买")])])])])]),_c('div',{staticClass:"duihuan-right-dingdan-bottom-font"},[_c('div',{staticClass:"duihuan-right-dingdan-two-left"},[_vm._m(2),_c('ul',{staticClass:"duihuan-right-dingdan-two-left2"},[_c('li',[_vm._v(_vm._s(_vm.orderInfoData.user_name))]),_c('li',[_vm._v(_vm._s(_vm.orderInfoData.user_mobile))]),_c('li',[_vm._v(_vm._s(_vm.orderInfoData.user_address))])])]),_c('ul',{staticClass:"duihuan-right-dingdan-two-right"},[_vm._m(3),_c('div',{staticClass:"duihuan-right-dingdan-two-right2"},[_c('li',[_vm._v(_vm._s(_vm.orderInfoData.created_at)+" 1111")]),_c('li',[_vm._v("爪币支付")]),_c('li',[_vm._v(_vm._s(_vm.orderInfoData.pay_time))]),_c('li',[_vm._v(_vm._s(_vm.orderInfoData.receive_at))])])])])]),_c('div',{staticClass:"duihuan-right-two"},[_c('div',{staticClass:"duihuan-right-one-top"},[_vm._v("物流信息")]),_c('div',{staticClass:"duihuan-right-wuliu-number"},[(_vm.shippingData.length == 0)?_c('p',{staticStyle:{"width":"100%","text-align":"center","font-size":"12px","color":"#9ca3af"}},[_vm._v(" 没有找到您的物流信息! ")]):_vm._e(),_c('div',{staticClass:"duihuan-right-wuliu-one"},_vm._l((_vm.shippingData),function(shipping,shippingIndex){return _c('li',{key:shippingIndex},[_vm._v(" 03-15   13:53:31 ")])}),0),_c('div',{staticClass:"duihuan-right-wuliu-three"},_vm._l((_vm.shippingData),function(shipping,shippingIndex){return _c('li',{key:shippingIndex},[_vm._v(" 顺丰快递后沙峪中心（010-345774）已揽收 ")])}),0)])]),_c('div',{staticClass:"duihuan-right-three"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.detailsData,"empty-text":"暂无兑换记录","border":"","header-cell-style":{
                    'text-align': 'center',
                    height: '36px',
                    color: '#000',
                    'font-size': '14px',
                    background: '#f4f4f4',
                  },"cell-style":{
                    'text-align': 'center',
                    height: '50px',
                    color: '#575757',
                    'font-size': '12px',
                  },"stripe":""}},[_c('el-table-column',{attrs:{"prop":"goods_thumb","label":"单价","width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(scope.row.goods_thumb[0]),expression:"scope.row.goods_thumb[0]"}],staticClass:"goods-thumb",staticStyle:{"width":"100px"},attrs:{"alt":""}}),_vm._v(" "),_c('br'),_vm._v(" "+_vm._s(scope.row.goods_name)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"price","label":"单价"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.coin_amount)+" 爪币 ")]}}])}),_c('el-table-column',{attrs:{"prop":"count","label":"数量"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" x "+_vm._s(scope.row.count)+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"address","label":"实付款"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.amount)+" 爪币 "),_c('br'),_vm._v(" 含运费："+_vm._s(scope.row.shipping_fee)+" 爪币 ")]}}])})],1),_c('div',{staticClass:"duihuan-right-shangpin-right"},[_vm._m(4),_c('ul',{staticClass:"duihuan-right-shangpin-right2"},[_c('li',[_vm._v(_vm._s(_vm.orderInfoData.goods_amount)+" 爪币")]),_c('li',[_vm._v("包邮")]),_c('li',[_vm._v(_vm._s(_vm.orderInfoData.need_pay_amount)+" 爪币")])])])],1)])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"duihuan-right-dingdan-one-bianhao1-font"},[_c('li',[_vm._v("订单编号：")]),_c('li',[_vm._v("订单状态：")]),_c('li',[_vm._v("快递单号：")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',[_c('a',{attrs:{"href":"javascript:void(0);"}},[_vm._v("退货")]),_vm._v(" / "),_c('a',{attrs:{"href":"javascript:void(0);"}},[_vm._v("退款")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"duihuan-right-dingdan-two-left1"},[_c('li',[_vm._v("收件人：")]),_c('li',[_vm._v("联系电话：")]),_c('li',[_vm._v("收货地址：")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"duihuan-right-dingdan-two-right1"},[_c('li',[_vm._v("下单时间：")]),_c('li',[_vm._v("付款方式：")]),_c('li',[_vm._v("付款时间：")]),_c('li',[_vm._v("发货时间：")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"duihuan-right-shangpin-right1"},[_c('li',[_vm._v("商品金额：")]),_c('li',[_vm._v("运费：")]),_c('li',[_vm._v("应付金额：")])])}]

export { render, staticRenderFns }