<template>
  <div class="duihuans">
    <div class="duihuan">

      <!-- 订单记录和编号 -->
      <div class="duihuan-right" v-show="true">
        <div class="page-header">
          <p>
            <strong>订单详情-{{orderInfoData.sn}}</strong>
          </p>
        </div>
        <div class="duihuan-right-ding">
          <div class="duihuan-right-ding-center">
            <!-- 订单信息 -->
            <div class="duihuan-right-dingdan">
              <!-- 分为上中下三部分 -->
              <div class="duihuan-right-one">
                <div class="duihuan-right-dingdan-top">订单信息</div>
                <div class="duihuan-right-dingdan-bottom">
                  <div class="duihuan-right-dingdan-bottom-left">
                    <!-- 左边分为左右两部分 -->
                    <div class="duihuan-right-dingdan-one-bianhao1-font">
                      <li>订单编号：</li>
                      <li>订单状态：</li>
                      <li>快递单号：</li>
                    </div>
                    <div class="duihuan-right-dingdan-one-bianhao1-number">
                      <li>{{ orderInfoData.sn }}</li>
                      <li>
                        {{ orderInfoData.status_name }}
                        <span v-show="false">签收后15天系统将自动确认收货</span>
                      </li>
                      <li></li>
                    </div>
                  </div>
                  <div class="duihuan-right-dingdan-bottom-center">
                    <ul>
                      <li>
                        <img src="@/icons/svg/shop/倒计时12X12.svg" alt=""/>
                        <span>{{ orderInfoData.expired_time }}</span>
                      </li>
                      <li>
                        <button class="button-blue-100-14" @click="confimGoods">
                          确认收货
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div class="duihuan-right-dingdan-bottom-right">
                    <ul>
                      <li>
                        <a href="javascript:void(0);">退货</a>
                        /
                        <a href="javascript:void(0);">退款</a>
                      </li>
                      <li>
                        <a
                            href="javascript:void(0);"
                            @click="
                            $router.push({
                              path: 'detail',
                              query: { id: goodsId },
                            })
                          "
                        >再次购买</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="duihuan-right-dingdan-bottom-font">
                  <div class="duihuan-right-dingdan-two-left">
                    <ul class="duihuan-right-dingdan-two-left1">
                      <li>收件人：</li>
                      <li>联系电话：</li>
                      <li>收货地址：</li>
                    </ul>
                    <ul class="duihuan-right-dingdan-two-left2">
                      <li>{{ orderInfoData.user_name }}</li>
                      <li>{{ orderInfoData.user_mobile }}</li>
                      <li>{{ orderInfoData.user_address }}</li>
                    </ul>
                  </div>
                  <ul class="duihuan-right-dingdan-two-right">
                    <div class="duihuan-right-dingdan-two-right1">
                      <li>下单时间：</li>
                      <li>付款方式：</li>
                      <li>付款时间：</li>
                      <li>发货时间：</li>
                    </div>
                    <div class="duihuan-right-dingdan-two-right2">
                      <li>{{ orderInfoData.created_at }} 1111</li>
                      <li>爪币支付</li>
                      <li>{{ orderInfoData.pay_time }}</li>
                      <li>{{ orderInfoData.receive_at }}</li>
                    </div>
                  </ul>
                </div>
              </div>
              <!-- 中间 -->
              <div class="duihuan-right-two">
                <div class="duihuan-right-one-top">物流信息</div>
                <div class="duihuan-right-wuliu-number">
                  <p v-if="shippingData.length == 0" style="
                      width: 100%;
                      text-align: center;
                      font-size: 12px;
                      color: #9ca3af;
                    ">
                    没有找到您的物流信息!
                  </p>
                  <div class="duihuan-right-wuliu-one">
                    <li v-for="(shipping, shippingIndex) in shippingData" :key="shippingIndex">
                      03-15 &nbsp;&nbsp;13:53:31
                    </li>
                  </div>
                  <div class="duihuan-right-wuliu-three">
                    <li v-for="(shipping, shippingIndex) in shippingData" :key="shippingIndex">
                      顺丰快递后沙峪中心（010-345774）已揽收
                    </li>
                  </div>
                </div>
              </div>

              <!-- 顶部 -->
              <div class="duihuan-right-three">
                <el-table
                    :data="detailsData"
                    empty-text="暂无兑换记录"
                    border style="width: 100%"
                    :header-cell-style="{
                    'text-align': 'center',
                    height: '36px',
                    color: '#000',
                    'font-size': '14px',
                    background: '#f4f4f4',
                  }"
                    :cell-style="{
                    'text-align': 'center',
                    height: '50px',
                    color: '#575757',
                    'font-size': '12px',
                  }"
                    stripe>
                  <el-table-column prop="goods_thumb" label="单价" width="200">
                    <template slot-scope="scope">
                      <img class="goods-thumb" style="width: 100px;" v-lazy="scope.row.goods_thumb[0]" alt=""/> <br/>
                      {{scope.row.goods_name}}
                    </template>
                  </el-table-column>
                  <el-table-column prop="price" label="单价">
                    <template slot-scope="scope">
                      {{ scope.row.coin_amount }} 爪币
                    </template>
                  </el-table-column>
                  <el-table-column prop="count" label="数量">
                    <template slot-scope="scope">
                      x {{ scope.row.count }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="address" label="实付款">
                    <template slot-scope="scope">
                      {{ scope.row.amount }} 爪币 <br/>
                      含运费：{{ scope.row.shipping_fee }} 爪币
                    </template>
                  </el-table-column>
                </el-table>

<!--                <div class="duihuan-right-three-top">-->
<!--                  <li>商品信息</li>-->
<!--                  <li>单价</li>-->
<!--                  <li>数量</li>-->
<!--                  <li>实付</li>-->
<!--                </div>-->
<!--                <div class="duihuan-right-three-center">-->
<!--                  <ul-->
<!--                      v-for="(detail, detailIndex) in detailsData"-->
<!--                      :key="detailIndex"-->
<!--                  >-->
<!--                    <li>-->
<!--                      <img v-lazy="detail.goods_thumb" alt=""/>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                      <p>-->
<!--                        {{ detail.goods_name }}-->
<!--                      </p>-->
<!--                    </li>-->
<!--                    <li>{{ detail.amount }}</li>-->
<!--                    <li>X{{ detail.count }}</li>-->
<!--                    <li>{{ detail.coin_amount }} 爪币</li>-->
<!--                  </ul>-->
<!--                </div>-->
                <div class="duihuan-right-shangpin-right">
                  <ul class="duihuan-right-shangpin-right1">
                    <li>商品金额：</li>
                    <li>运费：</li>
                    <li>应付金额：</li>
                  </ul>
                  <ul class="duihuan-right-shangpin-right2">
                    <li>{{ orderInfoData.goods_amount }} 爪币</li>
                    <li>包邮</li>
                    <li>{{ orderInfoData.need_pay_amount }} 爪币</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {orderInfo} from "@/api/goods";

export default {
  name: "orderinfo",
  components: {},
  data() {
    return {
      goodsId: 0,
      orderInfoData: {},
      detailsData: [],
      shippingData: [],
    };
  },
  created() {
    if (!this.$store.getters["useUserStore/getToken"]) {
      this.$notify({
        type: "error",
        message: "请先登录！",
      });
      this.$router.push("/login");
      return false;
    }
    this.loadOrderInfo({id: this.$route.query.order_id});
  },
  methods: {
    async loadOrderInfo(data) {
      const res = await orderInfo(data);
      if (res.statusCode == 200) {
        this.orderInfoData = res.data.info;
        this.detailsData = res.data.details;
        this.shippingData = res.data.shipping;
        this.goodsId = res.data.details[0].goods_id;
      }
    },
    confimGoods() {
      this.$confirm("请先确定您已收到货品，确认后只能退货，是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            this.$notify({
              type: "success",
              message: "操作成功",
            });
          })
          .catch(() => {
          });
    },
  },
};
</script>

<style lang="less" scoped>
.duihuans {
  background-color: #f8f8f8;
  min-height: 930px;
}
.page-header {
  height: 76px;
  border-bottom: 1px solid #b5b5b5;
  display: flex;
  background-color: #fff;

  p {
    font-size: 18px;
    margin: 40px;
  }
}
.all-banner img {
  width: 100%;
  height: 224px;
  margin-top: -20px;
  margin-bottom: 20px;
}

.duihuan {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .duihuan-right {
    width: 940px;
    box-sizing: border-box;

    .duihuan-right-top {
      width: 100%;
      line-height: 36px;
      font-size: 16px;
      color: #474747;
      margin-bottom: 10px;
    }

    .duihuan-right-ding {
      width: 940px;
      background: #ffffff;
      min-height: 860px;
      .duihuan-right-ding-center {
        width: 860px;
        margin: 0 auto;
        position: relative;
        top: 20px;

        .duihuan-right-dingdan {
          width: 860px;
          height: 288px;

          .duihuan-right-one {
            .duihuan-right-dingdan-top {
              width: 860px;
              line-height: 36px;
              background: #f8f8f8;
              text-indent: 10px;
              font-size: 14px;
              color: #000;
              font-weight: 400;
            }

            .duihuan-right-dingdan-bottom {
              display: flex;
              justify-content: space-between;
              width: 100%;
              height: 100px;
              border-bottom: 1px solid #b5b5b6;

              .duihuan-right-dingdan-bottom-left {
                display: flex;
                margin-top: 19px;

                .duihuan-right-dingdan-one-bianhao1-font {
                  margin-left: 10px;

                  li {
                    font-weight: 400;
                    font-size: 14px;
                    color: #474747;
                    margin-bottom: 10px;
                  }
                }

                .duihuan-right-dingdan-one-bianhao1-number {
                  li {
                    font-weight: 400;
                    font-size: 12px;
                    color: #474747;
                    margin-bottom: 12px;
                  }
                }
              }

              .duihuan-right-dingdan-bottom-center {
                margin-top: 19px;
                width: 100px;

                & ul li:first-of-type {
                  margin-bottom: 6px;
                }

                img {
                  width: 12px;
                  height: 12px;
                  margin-right: 11px;
                }

                span {
                  color: #474747;
                  font-size: 12px;
                }
              }

              .duihuan-right-dingdan-bottom-right {
                margin-top: 19px;
                margin-right: 32px;

                ul li {
                  margin-bottom: 10px;

                  a {
                    font-size: 14px;
                    color: #595957;
                  }
                }

                ul li:last-of-type {
                  position: relative;
                  left: 3px;
                }
              }
            }

            .duihuan-right-dingdan-bottom-font {
              display: flex;
              margin-top: 20px;
              margin-bottom: 40px;

              .duihuan-right-dingdan-two-left {
                margin-left: 10px;
                font-size: 14px;
                font-weight: 400;
                color: #474747;
                width: 440px;
                border-right: 1px solid #b5b5b6;
                height: 88px;
                display: flex;

                .duihuan-right-dingdan-two-left1 {
                  text-align: right;
                }

                .duihuan-right-dingdan-two-left2 {
                  width: 168px;
                  font-size: 12px;
                }

                .duihuan-right-dingdan-two-left2 li:last-of-type {
                  line-height: 24px;
                  position: relative;
                  top: -5px;
                }

                li {
                  margin-bottom: 10px;
                }
              }

              .duihuan-right-dingdan-two-right {
                font-size: 14px;
                font-weight: 400;
                color: #474747;
                display: flex;
                margin-left: 40px;

                .duihuan-right-dingdan-two-right1 {
                  li {
                    text-align: left;
                    margin-bottom: 10px;
                  }
                }

                .duihuan-right-dingdan-two-right2 {
                  font-size: 12px;

                  li {
                    margin-bottom: 12px;
                  }
                }
              }
            }
          }

          .duihuan-right-two {
            width: 860px;
            min-height: 162px;

            .duihuan-right-one-top {
              width: 860px;
              line-height: 36px;
              background: #f8f8f8;
              font-size: 14px;
              color: #000;
              text-indent: 10px;
            }

            .duihuan-right-wuliu-number {
              display: flex;
              margin: 20px 0 0 10px;

              .duihuan-right-wuliu-one {
                li {
                  padding-right: 40px;
                  border-right: 1px solid #b5b5b6;
                  font-size: 14px;
                  margin-bottom: 10px;
                  color: #474747;
                }
              }

              .duihuan-right-wuliu-three {
                font-size: 14px;
                margin-left: 35px;

                li {
                  margin-bottom: 10px;
                }
              }
            }
          }

          .duihuan-right-three {
            width: 860px;
            height: 247px;

            .duihuan-right-three-top {
              width: 860px;
              line-height: 32px;
              background-color: #f8f8f8;
              display: flex;
              text-align: center;
              font-size: 14px;
              color: #575757;

              & > li:nth-of-type(1) {
                width: 360px;
              }

              & > li:nth-of-type(2) {
                width: 180px;
              }

              & > li:nth-of-type(3) {
                width: 140px;
              }

              & > li:nth-of-type(4) {
                width: 180px;
              }
            }

            .duihuan-right-three-center {
              height: 110px;
              border-bottom: 1px solid #b5b5b6;

              ul {
                position: relative;
                top: 20px;
                display: flex;

                li {
                  font-size: 14px;
                  font-weight: 400;

                  img {
                    width: 70px;
                    height: 70px;
                  }
                }

                & > li:nth-of-type(1) {
                  margin-right: 10px;
                }

                & > li:nth-of-type(2) {
                  margin-left: 14px;
                  width: 266px;

                  p {
                    display: block;
                    width: 266px;
                    height: 38px;
                    line-height: 20px;
                    color: #474747;
                  }
                }

                & > li:nth-of-type(3) {
                  width: 180px;
                  text-align: center;
                  color: #595757;
                }

                & > li:nth-of-type(4) {
                  width: 140px;
                  text-align: center;
                  color: #575757;
                }

                & > li:nth-of-type(5) {
                  width: 180px;
                  text-align: center;
                  color: #595757;
                }
              }
            }

            .duihuan-right-shangpin-right {
              display: flex;
              height: 105px;
              justify-content: flex-end;

              ul {
                margin-top: 20px;

                li {
                  font-size: 14px;
                  margin-bottom: 10px;
                }
              }

              .duihuan-right-shangpin-right1 {
                text-align: right;

                li {
                  color: #3A3939;
                }
              }

              .duihuan-right-shangpin-right2 {
                margin: 20px 69px 0 6px;

                li {
                  color: #474747;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
